<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <loader v-if="loaders.reading" />

                <div v-else>

                    <!-- Breadcrumb -->
                    <breadcrumb />

                    <div class="card card-body mb-3">
                        <div class="row">
                            <div class="col-lg-8">
                                <h5 class="font-weight-bold m-0">Modifica informazioni paziente</h5>

                                <p class="text-muted mb-3">
                                    <small>
                                        Le modifiche nei confronti di un paziente sono limitate
                                    </small>
                                </p>
                            </div>
                            <div class="col-lg-4 text-danger text-right" id="decesso">Paziente deceduto</div>
                        </div>    
                        <form @submit.prevent="updatePatient" autocomplete="off" class="mb-3">

                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="CF">Codice fiscale</label>
                                        <input type="text" class="form-control f-family-monospace text-uppercase" id="CF" :value="form.DB.CF" disabled>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="name">Nome</label>
                                        <input type="text" class="form-control text-uppercase" id="name" v-model.trim="$v.form.DB.name.$model" v-bind:class="{'is-invalid': !$v.form.DB.name.required}" disabled>

                                        <div v-if="!$v.form.DB.name.required" class="invalid-feedback">
                                            Nome richiesto
                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="surname">Cognome</label>
                                        <input type="text" class="form-control text-uppercase" id="surname" v-model.trim="$v.form.DB.surname.$model" v-bind:class="{'is-invalid': !$v.form.DB.surname.required}" disabled>

                                        <div v-if="!$v.form.DB.surname.required" class="invalid-feedback">
                                            Cognome richiesto
                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="date_birth">Data di nascita</label>
                                        <input type="date" class="form-control" id="date_birth" v-model="$v.form.DB.date_birth.$model" disabled>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="sex">Sesso</label>

                                        <select class="form-control" id="sex" v-model.trim="$v.form.DB.sex.$model" disabled>
                                            <option value="M">
                                                UOMO
                                            </option>
                                            <option value="F">
                                                DONNA
                                            </option>
                                        </select>
                                        
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="address">Indirizzo</label>
                                        <input type="text" class="form-control" id="address" v-model.trim="$v.form.DB.address.$model">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="surname">ID paziente</label>
                                        <input type="text" class="form-control text-uppercase" id="id" :value="form.DB.id" disabled>

                                    </div>
                                </div>   
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="surname">ID recup</label>
                                        <input type="text" class="form-control text-uppercase" id="recup_personId" :value="form.DB.recup_personId" disabled>

                                    </div>
                                </div>                                                             
                            </div>

                            <p class="font-weight-bold mb-0">Note sul paziente</p>

                            <p class="text-muted mb-3">
                                <small>
                                    Aggiungi delle note e delle informazioni utili nei confronti di questo paziente
                                </small>
                            </p>

                            <div class="form-group">

                                <textarea class="form-control" id="note" rows="3" maxlength="1000" v-model.trim="$v.form.DB.note.$model" v-bind:class="{'is-invalid': !$v.form.DB.note.maxLength || validation.update.patient.ko, 'is-valid': validation.update.patient.ok}"></textarea>

                                <small class="form-text text-muted" v-show="form.DB.note.length > 0">
                                    {{form.DB.note.length}}/1000 Caratteri
                                </small>

                                <div class="invalid-feedback" v-show="!$v.form.DB.note.maxLength">
                                    Le note devono essere inferiori a 1000 caratteri
                                </div>
                                <div class="invalid-feedback" v-show="validation.update.patient.ko">
                                    {{validation.update.patient.ko}}
                                </div>
                                <div class="valid-feedback" v-show="validation.update.patient.ok">
                                    {{validation.update.patient.ok}}
                                </div>

                            </div>

                            <button v-if="!functionDisabled(4, 'update')" class="btn btn-ifo" :disabled="loaders.updating.patient || $v.form.DB.$invalid || !$v.form.DB.$anyDirty">
                                <span v-show="!loaders.updating.patient">Modifica</span>
                                <div v-show="loaders.updating.patient" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>
                            </button>

                        </form>

                        <p class="font-weight-bold m-0">Recapiti</p>

                        <p class="text-muted mb-3">
                            <small>
                                Il numero di cellulare e l'email vengono utilizzate per l'invio di informazioni al paziente
                            </small>
                        </p>

                        <div v-show="validation.ko" class="alert alert-danger">
                            {{validation.ko}}
                        </div>

                        <form @submit.prevent="updatePhone" autocomplete="off" class="mb-3">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="phone">Numero di cellulare</label>
                                        <input type="phone" class="form-control f-family-monospace" id="phone" v-model="form.DB.phone" disabled>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="newPhone">Nuovo numero di cellulare</label>
                                        <input type="text" class="form-control" id="newPhone" v-model.trim="$v.form.recapiti.telefonico.phone.$model">

                                        <p v-if="!$v.form.recapiti.telefonico.phone.required && $v.form.recapiti.telefonico.phone.$dirty" class="m-0 text-danger">
                                            <small>Numero di cellulare richiesto</small>
                                        </p>
                                        <p v-if="$v.form.recapiti.telefonico.phone.equalOriginal && $v.form.recapiti.telefonico.phone.$dirty" class="m-0 text-danger">
                                            <small>Numero di cellulare uguale a quello originale</small>
                                        </p>

                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="confirmPhone">Conferma numero di cellulare</label>
                                        <input type="text" class="form-control" id="confirmPhone" v-model.trim="$v.form.confirm.phone.$model">

                                        <p v-if="!$v.form.confirm.phone.required && $v.form.confirm.phone.$dirty" class="m-0 text-danger">
                                            <small>Conferma numero di cellulare richiesto</small>
                                        </p>
                                        <p v-if="!$v.form.confirm.phone.equalsPhone && $v.form.confirm.phone.$dirty" class="m-0 text-danger">
                                            <small>Numeri di cellulare differenti</small>
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>

                            <button v-if="!functionDisabled(4, 'update')" class="btn btn-ifo" @click="updatePhone" :disabled="(!$v.form.recapiti.telefonico.phone.required || !$v.form.confirm.phone.equalsPhone || $v.form.recapiti.telefonico.phone.equalOriginal) || loaders.updating.phone">
                                Modifica
                            </button>

                        </form>

                        <form @submit.prevent="updateEmail" autocomplete="off" class="mb-3">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="email">Indirizzo email</label>
                                        <input type="text" class="form-control" id="email" v-model="form.DB.email" disabled>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="newEmail">Nuovo indirizzo email</label>
                                        <input type="text" class="form-control" id="newEmail" v-model.trim="$v.form.recapiti.elettronico.email.$model">

                                        <p v-if="!$v.form.recapiti.elettronico.email.required && $v.form.recapiti.elettronico.email.$dirty" class="m-0 text-danger">
                                            <small>Indirizzo email richiesto</small>
                                        </p>
                                        <p v-if="$v.form.recapiti.elettronico.email.equalOriginal && $v.form.recapiti.elettronico.email.$dirty" class="m-0 text-danger">
                                            <small>Indirizzo email uguale a quello originale</small>
                                        </p>

                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="confirmEmail">Conferma indirizzo email</label>
                                        <input type="text" class="form-control" id="confirmEmail" v-model.trim="$v.form.confirm.email.$model">

                                        <p v-if="!$v.form.confirm.email.required && $v.form.confirm.email.$dirty" class="m-0 text-danger">
                                            <small>Conferma indirizzo email richiesto</small>
                                        </p>
                                        <p v-if="!$v.form.confirm.email.equalsEmail && $v.form.confirm.email.$dirty" class="m-0 text-danger">
                                            <small>Indirizzi email differenti</small>
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>

                            <button v-if="!functionDisabled(4, 'update')" class="btn btn-ifo" @click="updateEmail" :disabled="(!$v.form.recapiti.elettronico.email.required || !$v.form.confirm.email.equalsEmail || $v.form.recapiti.elettronico.email.equalOriginal) || loaders.updating.email">
                                Modifica
                            </button>

                        </form>

                        <p class="font-weight-boldm-0">Caregiver</p>

                        <p class="text-muted mb-3">
                            <small>
                                Il numero di cellulare e l'email vengono utilizzate per l'invio di informazioni al paziente
                            </small>
                        </p>

                        <div v-show="validation.ko" class="alert alert-danger">
                            {{validation.ko}}
                        </div>

                        <form @submit.prevent="updatePatient" autocomplete="off" class="mb-3">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="namecg">Nome</label>
                                        <input type="text" class="form-control text-uppercase" id="namecg" v-model.trim="$v.form.DB.namecg.$model">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="surnamecg">Cognome</label>
                                        <input type="text" class="form-control text-uppercase" id="surnamecg" v-model.trim="$v.form.DB.surnamecg.$model">

                                    </div>
                                </div>                               
                            </div>
                            <button v-if="!functionDisabled(4, 'update')" class="btn btn-ifo" @click="updatePatient" :disabled="loaders.updating.patient  || $v.form.DB.$invalid || !$v.form.DB.$anyDirty">
                                Modifica
                            </button>                           
                        </form> 
                        <form @submit.prevent="updatePhoneCG" autocomplete="off" class="mb-3">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="phone">Numero di cellulare</label>
                                        <input type="phone" class="form-control f-family-monospace" id="phonecg" v-model="form.DB.phonecg" disabled>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="newPhone">Nuovo numero di cellulare</label>
                                        <input type="text" class="form-control" id="newPhonecg" v-model.trim="$v.form.recapiti.telefonico.phonecg.$model">

                                        <p v-if="$v.form.recapiti.telefonico.phonecg.equalOriginal && $v.form.recapiti.telefonico.phonecg.$dirty" class="m-0 text-danger">
                                            <small>Numero di cellulare uguale a quello originale</small>
                                        </p>

                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="confirmPhone">Conferma numero di cellulare</label>
                                        <input type="text" class="form-control" id="confirmPhonecg" v-model.trim="$v.form.confirm.phonecg.$model">

                                        <p v-if="!$v.form.confirm.phonecg.equalsPhone && $v.form.confirm.phonecg.$dirty" class="m-0 text-danger">
                                            <small>Numeri di cellulare differenti</small>
                                        </p>
                                        
                                    </div>
                                </div>  
                            
                            </div>

                            <button v-if="!functionDisabled(4, 'update')" class="btn btn-ifo" @click="updatePhoneCG" :disabled="(!$v.form.recapiti.telefonico.phonecg.required || !$v.form.confirm.phonecg.equalsPhone || $v.form.recapiti.telefonico.phonecg.equalOriginal) || loaders.updating.phonecg">
                                Modifica
                            </button>

                        </form>                        
                        <form @submit.prevent="updateEmailCG" autocomplete="off" class="mb-3">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="emailcg">Indirizzo email</label>
                                        <input type="text" class="form-control" id="emailcg" v-model="form.DB.emailcg" disabled>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="newEmail">Nuovo indirizzo email</label>
                                        <input type="text" class="form-control" id="newEmailcg" v-model.trim="$v.form.recapiti.elettronico.emailcg.$model">

                                        <p v-if="$v.form.recapiti.elettronico.emailcg.equalOriginal && $v.form.recapiti.elettronico.emailcg.$dirty" class="m-0 text-danger">
                                            <small>Indirizzo email uguale a quello originale</small>
                                        </p>

                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="confirmEmail">Conferma indirizzo email</label>
                                        <input type="text" class="form-control" id="confirmEmailcg" v-model.trim="$v.form.confirm.emailcg.$model">

                                        <p v-if="!$v.form.confirm.emailcg.equalsEmail && $v.form.confirm.emailcg.$dirty" class="m-0 text-danger">
                                            <small>Indirizzi email differenti</small>
                                        </p>
                                        
                                    </div>
                                </div>
                                                           
                            </div>

                            <button v-if="!functionDisabled(4, 'update')" class="btn btn-ifo" @click="updateEmailCG" :disabled="(!$v.form.recapiti.elettronico.emailcg.required || !$v.form.confirm.emailcg.equalsEmail || $v.form.recapiti.elettronico.emailcg.equalOriginal) || loaders.updating.emailcg">
                                Modifica
                            </button>

                        </form> 
                    </div>
                    
                    <b-tabs content-class="border border-top-0">
                        <b-tab title="Patologie associate" active>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-10 p-3">

                                        <div v-if="patients_pathologies.length < 1" class="alert alert-warning m-0">
                                            Ancora nessuna patologia associata
                                        </div>

                                        <div class="table-responsive m-0" v-else>
                                            <table class="table table-bordered table-hover table-sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            Patologia
                                                        </th>
                                                        <th scope="col">
                                                            Terapia
                                                        </th>                                                        
                                                        <th scope="col">
                                                            Durata visita (minuti)
                                                        </th>
                                                        <th scope="col">
                                                            Visibilità
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <router-link tag="tr" v-for="patient_pathology of patients_pathologies" :key="patient_pathology.id" :to="`/user/pazienti/modifica/${$route.params.CF}/associazione/patologie/modifica/${patient_pathology.id}`" v-bind:class="{'disabled_invisible': functionDisabled(10, 'read')}" class="hover cursor-pointer">
                                                        <td>
                                                            {{patient_pathology.pathology.title}}
                                                        </td>
                                                        <td>
                                                            {{ patient_pathology.therapy ? patient_pathology.therapy.title : "-"  }}
                                                        </td>                                                       
                                                        <td>
                                                            {{patient_pathology.sessions_duration}}
                                                        </td>
                                                        <td>
                                                            {{patient_pathology.state ? "Visibile" : "Non visibile"}}
                                                        </td>
                                                    </router-link>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div class="col-lg-2 p-0 border-left">
                                        <ul class="nav flex-column">
                                            <li class="nav-item">
                                                <router-link v-bind:class="{'disabled': functionDisabled(10, 'create')}" :to="`/user/pazienti/modifica/${form.DB.CF}/associazione/patologie/aggiungi`" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom">
                                                    Associa <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle ml-2" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                    </svg>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Appuntamenti" v-if="!functionDisabled(1, 'abilitation')">
                            <div class="container-fluid p-3">
                                
                                <div v-show="appointments.length < 1" class="alert alert-warning m-0">
                                    Ancora nessun appuntamento
                                </div>

                                <div class="row">
                                    <div v-for="appointment of appointments" :key="`appointment_${appointment.id}`" div class="col-lg-4">
                                        <div class="card card-body mb-2">
                                            <div class="row">
                                                <div class="col-7">
                                                    <p class="text-uppercase font-weight-bold">
                                                        {{appointment.pathology}}
                                                    </p>
                                                    <p class="m-0 text-truncate">
                                                        <small>
                                                            {{appointment.date | data_slash}} - {{appointment.h_start | data_ora}} / {{appointment.h_end | data_ora}}
                                                        </small>
                                                    </p>
                                                    <p class="m-0 text-truncate">
                                                        <small>
                                                            {{appointment.therapy ? appointment.therapy.title : "Nessuna terapia associata"}}
                                                        </small>
                                                    </p>
                                                    <p class="m-0 text-truncate">
                                                        <small>
                                                            Stanza 
                                                        </small>
                                                        <small :style="`color: ${appointment.rooms.color}`">
                                                            {{appointment.rooms.title}}
                                                        </small>
                                                    </p>
                                                </div>
                                                <div class="col-5 text-right d-flex flex-column justify-content-between">

                                                    <div class="overflow-hidden">
                                                        <div class="w-100 badge badge-primary" v-if="!appointment.totem_presence && !appointment.room_presence && !appointment.appointment_closed && !appointment.patient_pause && (new Date(appointment.date) > new Date())">
                                                            Programmata
                                                        </div>
                                                        <!-- Alert paziente in sala di attesa -->
                                                        <div class="w-100 badge badge-info d-flex justify-content-center align-items-center" v-if="appointment.totem_presence && !appointment.room_presence && !appointment.appointment_closed && !appointment.patient_pause">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-hourglass-split mr-1" viewBox="0 0 16 16">
                                                                <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                                                            </svg> Sala
                                                        </div>
                                                        <!-- Alert paziente in stanza per fare la visita -->
                                                        <div class="w-100 badge badge-danger d-flex justify-content-center align-items-center" v-if="appointment.totem_presence && appointment.room_presence && !appointment.appointment_closed && !appointment.patient_pause">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-play-circle mr-1" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                                            </svg> Visita
                                                        </div>
                                                        <div class="w-100 badge badge-warning d-flex justify-content-center align-items-center" v-if="appointment.totem_presence && appointment.room_presence && appointment.patient_pause && !appointment.patient_pause_back && !appointment.appointment_closed">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-pause-circle mr-1" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                                            </svg> Pausa
                                                        </div>
                                                        <div class="w-100 badge badge-danger d-flex justify-content-center align-items-center effetto-lampeggia-loop" v-if="appointment.totem_presence && appointment.room_presence && appointment.patient_pause && appointment.patient_pause_back && !appointment.appointment_closed">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-pause-circle mr-1" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5zm3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5z"/>
                                                            </svg> Pausa/Attesa
                                                        </div>                                                    
                                                        <div class="w-100 badge badge-success" v-if="appointment.totem_presence && appointment.room_presence && appointment.appointment_closed">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle mr-1" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                                            </svg> Completata
                                                        </div>
                                                        <div class="w-100 badge badge-success" v-if="appointment.therapy_programId">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-patch-check mr-1" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                                                <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/>
                                                            </svg> P. Teraupetico
                                                        </div>
                                                    </div>

                                                    <router-link :disabled="functionDisabled(1, 'read')" tag="button" :to="`/user/agenda/modifica/${appointment.id}`" class="btn btn-ifo btn-sm">
                                                        Gestisci
                                                    </router-link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </b-tab>
                    </b-tabs>

                </div>

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a @click="suspendPatient" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom" v-bind:class="{'disabled': loaders.suspending || functionDisabled(4, 'update')}">
                            <span v-show="!loaders.suspending && form.DB.state">
                                Rendi non visibile <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-circle ml-2" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                </svg>
                            </span>
                            <span v-show="!loaders.suspending && !form.DB.state">
                                Rendi visibile <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle ml-2" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                </svg>
                            </span>
                            <div v-show="loaders.suspending" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a v-b-toggle.elimina v-bind:class="{'disabled': loaders.deleting || functionDisabled(4, 'delete')}" class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            <span class="d-none d-md-block">Elimina</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a>
                        <b-collapse id="elimina">

                            <div class="alert alert-danger m-0">
                                Sicuro di voler eliminare questo paziente?
                            </div>

                            <div class="row">
                                <div class="col-6 pr-0">
                                    <button @click="delete_patient" class="btn btn-ifo btn-block btn-sm rounded-0" :disabled="loaders.deleting">
                                    
                                        <span v-show="!loaders.deleting">
                                            Si
                                        </span>

                                        <div v-show="loaders.deleting" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>
                                </div>
                                <div class="col-6 pl-0">
                                    <button v-b-toggle.elimina class="btn btn-ifo btn-block btn-sm rounded-0">No</button>
                                </div>
                            </div>

                        </b-collapse>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required,
        maxLength
    } from "vuelidate/lib/validators"

    import moment from "moment"
    import store  from "@/store"

    export default {
        data() {
            return {
                form: {
                    DB: {
                        id:         0,
                        name:       null,
                        surname:    null,
                        CF:         null,
                        date_birth: null,
                        phone:      null,
                        email:      null,
                        sex:        null,
                        address:    null,
                        note:       "",
                        namecg:     null,
                        surnamecg:  null,
                        phonecg:    null,
                        emailcg:    null,
                        recup_personId:    null                                           
                    },
                    recapiti: {
                        telefonico: {
                            phone:   null,
                            phonecg: null
                        },
                        elettronico: {
                            email:   null,
                            emailcg: null      
                        }
                    },
                    confirm: {
                        phone: null,
                        email: null,
                        phonecg: null,
                        emailcg: null                       
                    }
                },
                validation: {
                    ko: false,
                    update: {
                        patient: {
                            ko: false,
                            ok: false
                        }
                    }
                },
                loaders: {
                    reading:    true,
                    suspending: false,
                    deleting:   false,

                    updating: {
                        phone:   false,
                        email:   false,
                        phonecg: false,
                        emailcg: false,                       
                        patient: false
                    }
                    
                },
                patients_pathologies: [],
                appointments: [],
                therapies: {
                    DB: []
                }
            }
        },
        validations() {
            return {
                form: {
                    recapiti: {
                        telefonico: {
                            phone: {
                                required,
                                equalOriginal() {
                                    return this.form.DB.phone == this.form.recapiti.telefonico.phone
                                }
                            },
                            phonecg: {
                                required,
                                equalOriginal() {
                                    return this.form.DB.phonecg == this.form.recapiti.telefonico.phonecg
                                }
                            }                           
                        },
                        elettronico: {
                            email: {
                                required,
                                equalOriginal() {
                                    return this.form.DB.email == this.form.recapiti.elettronico.email
                                }
                            },
                            emailcg: {
                                required,
                                equalOriginal() {
                                    return this.form.DB.emailcg == this.form.recapiti.elettronico.emailcg
                                }
                            }                           
                        }
                    },
                    confirm: {
                        phone: {
                            required,
                            equalsPhone() {
                                return this.form.recapiti.telefonico.phone == this.form.confirm.phone
                            }
                        },
                        email: {
                            required,
                            equalsEmail() {
                                return this.form.recapiti.elettronico.email == this.form.confirm.email
                            }
                        },
                        phonecg: {
                            required,
                            equalsPhone() {
                                return this.form.recapiti.telefonico.phonecg == this.form.confirm.phonecg
                            }
                        },
                        emailcg: {
                            required,
                            equalsEmail() {
                                return this.form.recapiti.elettronico.emailcg == this.form.confirm.emailcg
                            }
                        }                       
                    },
                    DB: {
                        name: {
                            required
                        },
                        surname: {
                            required
                        },
                        note: {
                            maxLength: maxLength(1000)
                        },
                        date_birth: {},
                        sex: {},
                        address: {},
                        namecg: {},
                        surnamecg: {}                
                    }
                }
            }
        },
        methods: {
            async readTherapies() {
                try {

                    this.therapies.DB = (
                        await this.axios.get(`user/therapies/read/actives`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                }
                catch(e) {

                    this.$toast.open({
                        message: "Errore di connessione con il server",
                        type: "error"
                    })

                }
            },            
            async updatePhone() {

                this.loaders.updating.phone = true

                const {
                    phone
                } = this.form.recapiti.telefonico
                const {
                    CF
                } = this.$route.params

                try {

                    const patient = await this.axios.post(`user/patients/update/phone`, {
                        phone,
                        CF
                    },
                    {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: patient.data.msg,
                        type:   `success`
                    })
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.updating.phone = false
                }
                
            },

            async updatePhoneCG() {

                this.loaders.updating.phonecg = true

                const {
                    phonecg
                } = this.form.recapiti.telefonico
                const {
                    CF
                } = this.$route.params

                try {

                    const patient = await this.axios.post(`user/patients/update/phonecg`, {
                        phonecg,
                        CF
                    },
                    {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: patient.data.msg,
                        type:   `success`
                    })
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.updating.phonecg = false
                }

            },           
            async updateEmail() {

                this.loaders.updating.email = true

                const {
                    email
                } = this.form.recapiti.elettronico
                const {
                    CF
                } = this.$route.params

                try {

                    const patient = await this.axios.post(`user/patients/update/email`, {
                        email,
                        CF
                    },
                    {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: patient.data.msg,
                        type:   `success`
                    })
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.updating.email = false
                }
                
            },
            async updateEmailCG() {

                this.loaders.updating.emailcg = true

                const {
                    emailcg
                } = this.form.recapiti.elettronico
                const {
                    CF
                } = this.$route.params

                try {

                    const patient = await this.axios.post(`user/patients/update/emailcg`, {
                        emailcg,
                        CF
                    },
                    {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: patient.data.msg,
                        type:   `success`
                    })
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.updating.emailcg = false
                }

            },           
            async ricerca_asur() {

                // Uppercase
                const CF = this.$route.params.CF.toUpperCase()

                const {
                    patient
                } = (
                    await this.axios.post(`user/patients/search/asur`, {CF}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                ).data

                //console.log(patient);

                const decesso = document.getElementById('decesso');

                decesso.style.display = "none";

                if(patient.decesso != ""){
                    const message = "Paziente deceduto"

                    
                    decesso.style.display = "block";

                    this.$toast.open({
                        message: message,
                        type: `error`
                    })

                    return false
                }

            },              
            async readPatient() {
                this.ricerca_asur()

                try {

                    let patient = await this.axios.get(`user/patients/read/detail/${this.$route.params.CF}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    patient.data.date_birth = moment(patient.data.date_birth).format("YYYY-MM-DD")
                    this.form.DB            = patient.data
                    if(this.form.DB.note == null) this.form.DB.note = ""

                    if(patient.data.patients_pathologies) { 

                        // Popoliamo array delle terapie seguite
                        this.patients_pathologies = patient.data.patients_pathologies

                        // Popoliamo array degli appuntamenti estrapolandoli dalle terapie seguite
                        for(let patient_pathology of patient.data.patients_pathologies) {  
                            
                            for(let appointment of patient_pathology.appointment) {
                                // Aggiungiamo il titolo della patologia da visualizzare nella card
                                appointment.pathology = patient_pathology.pathology.title
                                this.appointments.push(appointment)
                                //console.log(appointment)
                            }                          
                        }

                    }
                    
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                    this.$router.push(`/user/pazienti`)

                }
                finally {
                    this.loaders.reading = false
                }

            },
            async suspendPatient() {

                this.loaders.suspending = true

                try {

                    const res = await this.axios.get(`user/patients/suspend/${this.$route.params.CF}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })

                    this.form.DB.state = !this.form.DB.state
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   'error'
                    })

                }
                finally {
                    this.loaders.suspending = false
                }

            },
            async updatePatient() {

                // Attivazione loader
                this.loaders.updating.patient = true

                // Reset form

                this.validation.update.patient.ok = false
                this.validation.update.patient.ko = false

                try {

                    const patient = await this.axios.post(`user/patients/update`, this.form.DB, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    const message = patient.data.msg

                    this.$toast.open({
                        message,
                        type:   `success`
                    })

                    this.validation.update.patient.ok = message
                    
                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type:   `error`
                    })

                    this.validation.update.patient.ko = message

                }
                finally {
                    this.loaders.updating.patient = false                
                }

            },
            async delete_patient() {

                this.loaders.deleting = true

                try {

                    const res = await this.axios.delete(`user/patients/${this.form.DB.CF}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   `success`
                    })

                    this.$router.push(`/user/pazienti`)
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   'error'
                    })

                    this.loaders.deleting = false

                }

            },
            functionDisabled(id_function, action) {

                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                    else if(sFunction.id_software_functions == id_function && sFunction[action]) {
                        return false
                    }
                }

                return true

            }
        },
        mounted() {
            this.readTherapies()
            this.readPatient()            
        }
    }

</script>