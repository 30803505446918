<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <div class="card card-body mb-2">

                    <p class="font-weight-bold m-0">Filtri di ricerca</p>

                    <p class="text-muted mb-3">
                        <small>
                            Compila i campi che seguono, la ricerca avverrà in automatico
                        </small>
                    </p>

                    <div class="row">
                        <div class="col-lg-3">
                            <input class="form-control text-uppercase mb-1 mb-lg-0" type="text" @input="readPatientsFilter(form.search.recup_personId)" v-model="form.search.surname" placeholder="Cognome">
                        </div>
                        <div class="col-lg-3">
                            <input class="form-control text-uppercase mb-1 mb-lg-0" type="text" @input="readPatientsFilter(form.search.recup_personId)" v-model="form.search.name" placeholder="Nome">
                        </div>
                        <div class="col-lg-3">
                            <input class="form-control text-uppercase" type="text" @input="readPatientsFilter(form.search.recup_personId)" v-model="form.search.CF" placeholder="Codice fiscale">
                        </div>      
                        <div class="col-lg-3">
                            <select class="form-control" id="recup_personId" @input="readPatientsFilter($event.target.value)" v-model="form.search.recup_personId">
                                <option value="1">
                                    RECUP
                                </option>
                                <option value="">
                                    TUTTI
                                </option>
                            </select>                           
                        </div>                                                                                                   
                    </div>
                    
                </div>

                <loader v-if="loaders.reading"/>

                <div v-else>

                    <div v-if="patients.DB.length < 1" class="alert alert-warning m-0">
                        Ancora nessun peziente
                    </div>
                    
                    <div v-else>
                        <div class="row">
                            <div class="col-lg-6 text-left">
                                <p>Totale pazienti: <strong>{{counter}}</strong></p>
                            </div>
                            <div class="col-lg-6 text-right">
                                <p>Record visualizzati: <strong>{{record}}</strong></p>
                            </div>                           
                        </div>    
                        <div class="row">
                            <div v-for="patient of patients.DB" :key="patient.id" div class="col-lg-4">
                                <div class="card card-body mb-2">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <p class="text-uppercase font-weight-bold text-truncate">
                                                {{patient.surname}} {{patient.name}}
                                            </p>
                                            <p class="m-0">
                                                <small>
                                                    {{patient.date_birth | data_slash}}
                                                </small>
                                            </p>
                                            <p class="m-0">
                                                <small>
                                                    {{patient.CF}}
                                                </small>
                                            </p>
                                            <p class="m-0">
                                                <small>
                                                    {{patient.phone}}
                                                </small>
                                            </p>
                                            <p class="m-0 text-truncate">
                                                <small>
                                                    {{patient.email}}
                                                </small>
                                            </p>
                                            <p class="text-truncate m-lg-0">
                                                <small>
                                                    {{patient.patients_pathologies.length}} {{(patient.patients_pathologies.length == 1) ? 'Patologia' : 'Patologie'}}
                                                </small>
                                            </p>
                                        </div>
                                        <div class="col-md-5 text-right d-flex flex-column justify-content-between">

                                            <div v-if="patient.state" class="badge badge-success mb-1">Visibile</div>
                                            <div v-else class="badge badge-warning mb-1">Non visibile</div>

                                            <router-link tag="button" :to="`/user/pazienti/modifica/${patient.CF}`" class="btn btn-ifo btn-sm" :disabled="functionDisabled(4, 'read')">
                                                Gestisci
                                            </router-link>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 text-left">
                                <p>Totale pazienti: <strong>{{counter}}</strong></p>
                            </div>
                            <div class="col-lg-6 text-right">
                                <p>Record visualizzati: <strong>{{record}}</strong></p>
                            </div>                           
                        </div>                          

                        <div v-if="patients.DB.length >= 30" class="card card-body p-2">
                            <button class="btn btn-ifo m-auto" @click="moreResults">Carica altri pazienti</button>
                        </div>

                    </div>

                </div>
            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <router-link class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" to="/user/pazienti/aggiungi" v-bind:class="{'disabled': functionDisabled(4, 'create')}">
                            <span class="d-none d-md-block">Aggiungi paziente</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle ml-2 mt-1" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import store  from '@/store'

    export default {
        data() {
            return {
                counter: 0,
                record: 0,
                patients: {
                    DB: []
                },
                loaders: {
                    reading: true
                },
                form: {
                    search: {
                        CF:      ``,
                        name:    ``,
                        surname: ``,
                        recup_personId:    ``
                    }
                },
                pagination: {
                    skip: 0,
                    take: 30
                }
            }
        },
        methods: {
            async readCounterTot() {

                try {               
                    const counter = await this.axios.get(`user/patients/read/all`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    }) 

                    this.counter = counter.data.length
               
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }           

            },

            async readPatients() {

                const where = {

                }
                const orderBy = {
                    surname: "asc"
                }
                
                const {
                    skip,
                    take
                } = this.pagination

                try {

                    const patients = await this.axios.post(`user/patients/read/search`, {where, orderBy, skip, take}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.patients.DB = patients.data

                    this.record = patients.data.length

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.reading = false
                }

            },
            async readPatientsFilter(valRecup) {

                this.form.search.recup_personId = valRecup
                
                
                this.loaders.reading = true

                if(this.isInSearching()) {
                    this.form.search.recup_personId = valRecup
                    for(let val in this.form.search) {
                        this.form.search[val] = this.form.search[val].toUpperCase()
                    }                    

                    let where
                    if(this.form.search.recup_personId == "1"){
                        where   = {
                            AND: [
                                {
                                    CF: {
                                        contains: this.form.search.CF
                                    }
                                },
                                {
                                    name: {
                                        contains: this.form.search.name
                                    }
                                },
                                {
                                    surname: {
                                        contains: this.form.search.surname
                                    }
                                },
                                {
                                    recup_personId: {
                                        not: null
                                    }
                                }                            
                            ]
                        }
                    } else {
                        where   = {
                            AND: [
                                {
                                    CF: {
                                        contains: this.form.search.CF
                                    }
                                },
                                {
                                    name: {
                                        contains: this.form.search.name
                                    }
                                },
                                {
                                    surname: {
                                        contains: this.form.search.surname
                                    }
                                }                          
                            ]
                        }                        
                    }

                    const orderBy = {
                        name: "asc"
                    }

                    this.pagination.skip = 0

                    const {
                        skip,
                        take
                    } = this.pagination

                    try {

                        const patients = await this.axios.post(`user/patients/read/search`, {where, orderBy, skip, take}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })

                        this.patients.DB = patients.data

                        this.record = patients.data.length

                    }
                    catch(e) {

                        this.$toast.open({
                            message: `Si è verificato un problema, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}`,
                            type:    `error`
                        })

                    }
                    finally {
                        this.loaders.reading = false
                    }
                }
                else {
                    this.readPatients()
                }
            },

            moreResults() {
                this.pagination.take += 30
                this.readPatientsFilter()
            },
            isInSearching() {
                return this.form.search.CF.length >= 3 || this.form.search.name.length >= 3 || this.form.search.surname.length >= 3 || this.form.search.recup_personId != ""
            },
            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            }
        },
        mounted() {
            this.readPatients()
            this.readCounterTot()
        }
    }

</script>